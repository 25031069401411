@use '../lib';

.pressRelease {   
  position: relative;  
  border-top: 2px solid rgba(0, 128, 0, 0);
  border-bottom: 2px solid rgba(0, 128, 0, 0);
  padding: 1px 0;

  &__holder{
    max-width: 60rem;
    margin: 0 auto;
    padding: 2.25rem 1.25rem;

    @include lib.mq($from: 'tablet') {
      padding: 3.75rem 2rem;
    }
  }

  &__head{
    @include lib.heading2();
  }

  &__list{
    margin: 1.5rem 0;
    @include lib.mq($from: 'tablet') {
      margin: 2.5rem 0;
    }
  }

  &__image{
    margin: 0 1.25rem 0.25rem 0;
  }

  &__thumbnail{
    border-radius: 0.625rem;
    // flex: 0 0 70px;
  }

  &__link{
    margin-bottom: 0.625rem;
    display: block;
  }

  &__item{
    // background: linear-gradient(180deg, #F8F3F1 0%, #F3F1EF 100%);
    background : #F3F1EF;
    border-radius: 10px;
    padding: 1.25rem;
    // margin-bottom: 0.625rem;
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
    transition: 0.5s;

    &:last-child{
      margin-bottom: 0;
    }

    &:hover{
      background: lib.color('body');

    }
  }

  &__detail{    
    flex: 1;
  }

  &__date{
    @include lib.bodyMetaMini(false, rgba(lib.color('heading'), 0.5));
  }

  &__title{
    @include lib.heading5();
    margin-top: 0.625rem;
    
    @include lib.mq($from: 'laptop') {
      max-width: 37.5rem;
    }
  }

  &__loader{
    height: 10rem;
  }

  &__filterBlock{
    display: flex;
    flex-direction: column;

    @include lib.mq($from: 'tablet') {
      flex-direction: row;
      align-items: center;
    }
  }

  &__filterSwitch{
    display: flex;
    // align-items: center;
    width: 100%;
    align-items: center;
    margin-right: 2rem;
  }

  &__filterText{
    @include lib.bodyText();
    margin-right: 1rem;
  }

  &__switch{
    width: 44px;
    height: 28px;
  }

  // &__sort{
  //   margin-left: auto;
  // }

  &--spaceTop{
    margin-top: 1.55rem;
  }

  &--spaceBottom{
    margin-bottom: 1.55rem;
  }

  &--bg{
    background: lib.color('secondaryTint4'); 
  }

}
