@use '../../lib';

.switch{
  position: relative;
    height: 28px;
    width: 44px;
    margin: 0;
    position: relative;
    background: #ccc;
    border-radius: 14px;
    cursor: pointer;
    -webkit-transition: background .25s ease 0s;
    -moz-transition: background .25s ease 0s;
    transition: background .25s ease 0s;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    display: inline-block;

  z-index: 1;

  &__switch_is-on {
    background-color: #ccc;
  }

  &__switch_is-off {
    background-color: #E95164;
  }

  &__toggle {
    height: 24px;
    width: 24px;
    background: #eee;
    display: inline-block;
    cursor: pointer;
    border-radius: 50%;
    position: absolute;
    -webkit-transform: translateX(1px);
    -moz-transform: translateX(1px);
    transform: translateX(1px);
    // top: 1.25px; 
    top: 1.85px;
    outline: 0;
    border: 0;
    -webkit-transition: background-color .25s ease 0s,-webkit-transform .25s ease 0s,-webkit-box-shadow .15s ease 0s;
    transition: background-color .25s ease 0s,-webkit-transform .25s ease 0s,-webkit-box-shadow .15s ease 0s;
    -moz-transition: background-color .25s ease 0s,transform .25s ease 0s,box-shadow .15s ease 0s,-moz-transform .25s ease 0s;
    transition: background-color .25s ease 0s,transform .25s ease 0s,box-shadow .15s ease 0s;
    transition: background-color .25s ease 0s,transform .25s ease 0s,box-shadow .15s ease 0s,-webkit-transform .25s ease 0s,-moz-transform .25s ease 0s,-webkit-box-shadow .15s ease 0s;
  }

  &__toggleRight {
    transform: translateX(2px);
  }

  &__toggleLeft {
    transform: translateX(18px);
  }
}