@use '../../lib';

.dropdown{
  position: relative;
  margin-top: 1.25rem;
  @include lib.mq($from: 'tablet') {
    margin-top: 0;
  }

  &__wrapper{
    position: absolute;
    padding: 1rem 1rem;
    top: 3rem;
    left: 0;
    min-width: 169px;
    width: 100%;
    z-index: 2;
    border: 1px solid rgba(0, 0, 0, 0.04);
    -webkit-box-shadow: 0 16px 24px 2px rgb(0 0 0 / 14%);
    box-shadow: 0 3px 6px 2px rgb(0 0 0 / 11%);
    background: #fff;
    border-radius: 0.225rem;
  }

  &__menu{
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 169px;
    overflow-y: scroll;

    @include lib.mq($from: 'tablet') {
      max-width: 920px;
      /* width */
      &::-webkit-scrollbar {
          width: 4px;
      }
      
      /* Track */
      &::-webkit-scrollbar-track {
          background: transparent; 
      }
      
      /* Handle */
      &::-webkit-scrollbar-thumb {
          background: rgba(136, 136, 136, 0.694);
          border-radius: 0.625rem;
      }
      
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
          background: #555; 
      }
   }
  }

  &__selected{
    margin-right: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__list{
    min-width: 169px;
    width: 100%;
    max-width: none;

    @include lib.mq($from: 'tablet') {
      max-width: 220px;
    }

    &--arrow{
        padding: 0.85rem 1.25rem 0.75rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
  }

  &__arrow{
    width: 5px;
    height: 13px;
    transition: all 200ms;

    &--open{
      transform: rotate(90deg);
    }
  }

  &__type{
    @include lib.bodyMetaMini(false, rgba(lib.color('text'), 0.5));
    margin-bottom: 0.5rem;
    display: block;
  }

  &__item{
    @include lib.bodyMetaMini(false, lib.color('text'));    
  }

  &__link{
    display: block;
    padding: 0.5rem 1.25rem;

    &:hover{
      background: rgba(lib.color('text'), 0.05);
    }
  }

  &--mr10{
    @include lib.mq($from: 'tablet') {
      margin-right: 0.625rem;
    }    
  }
}